import React, { Component } from "react"
import {Link} from 'gatsby'

import Layout from "../components/layout"
import GatedContent from "../components/gatedcontent"


const contentlist = [
			{
				name: "NegosyoNow Selenium Runs",
				url: "negosyonow-selenium"
			},
	];

export default class TestresultIndex extends Component {
	render() {
		return (
			<Layout showtitle={true} activePath='testresult' pageTitle="Test Results" privatePage={true}>
				<GatedContent>
					<div className={"margin-content"}>
						<ul>
						{
							contentlist.map((data,index) => {
									return <li key={"listitem"+index}><Link to={data.url}>{data.name}</Link></li>;
							})
						}
						</ul>
					</div>
				</GatedContent>
			</Layout>
		)
	}
}



